import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";


import { ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';

import awsconfig from './aws-exports';

import "@aws-amplify/ui-react/styles.css";
import { studioTheme } from "./ui-components";

Amplify.configure(awsconfig);


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
<ThemeProvider theme={studioTheme}>
  <App />
</ThemeProvider>
  </StrictMode>
);

// Navbar.js
import React, { useState } from "react";
import "./Navbar.css"; // Importa il file CSS
import ReactGA from 'react-ga';

ReactGA.initialize('G-6038PZRSS6');  // sostituisci con il tuo ID di tracciamento

const Navbar = () => {
  const [selectedButton, setSelectedButton] = useState(null);

  const navigateTo = (id) => {
    setSelectedButton(id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }

    // Registra l'evento con Google Analytics
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked Navbar Button',
      label: id  // Questo sarà 'section1', 'section2', ecc.
    });
  };

  return (
    <div className="navbar">
      <ScrollToTopButton />
      <button
        onClick={() => navigateTo("portfolio")}
        className={selectedButton === "section1" ? "selected" : ""}
      >
        Portfolio
      </button>
      <button
        onClick={() => navigateTo("section2")}
        className={selectedButton === "section2" ? "selected" : ""}
      >
       Biografia
      </button>
      {/* Aggiungi altri bottoni se necessario */}
    </div>
  );
};

export default Navbar;

const ScrollToTopButton = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Registra l'evento con Google Analytics
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked Scroll To Top Button',
      label: 'Scroll To Top'
    });
  };

  return (
    <button onClick={handleClick} className="scroll-to-top-button">
      ↑
    </button>
  );
};

// Modal.js
import React from 'react';
import './Modal.scss';


import {
    FormModelCreateForm 
   } from './ui-components';
  


const Modal = ({ isOpen, onClose, onSubmit }) => {
  if (!isOpen) return null;

  const handleSubmit = () => {
    const confirmation = onSubmit();
    if (confirmation) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        
        <button className="close-button" onClick={onClose}>←</button>
        <FormModelCreateForm
          onSubmit={(fields) => {
            // Logica di elaborazione del modulo
            // ...
            return 'Submission Successful';  // Ritorna una stringa di conferma
          }}
        />
        {/* <button className="submit-button" onClick={handleSubmit}>Submit</button> */}
      </div>
    </div>
  );
};

export default Modal;

import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import "./styles.scss";
import { DominoSpinner } from 'react-spinners-kit';
// import { initGA } from './Analytics';
import LazyImage from './LazyImage';
import Image1 from "./assets/programms.png";
import Image from "./assets/vitalipisani.png";
import Speedart from "./assets/gif/speedart.gif";
import TheHood9 from "./assets/gif/thehood9.gif";
import TheHood8 from "./assets/gif/thehood8.gif";
import sento from "./assets/gif/sento.gif"
import directed from "./assets/gif/directed.gif"
import BeQuiet from "./assets/gif/bequiet.gif";
import Pochet from "./assets/gif/pochet.gif";
import Sesolo from "./assets/gif/sesolo.gif";
import yep from "./assets/gif/yep.gif";
import brasky from "./assets/gif/brasky.gif"
import morphine from "./assets/gif/morphine.gif"
import theOne from "./assets/gif/theOne.gif";
import keytech from "./assets/gif/keytech.gif";
import basket from "./assets/gif/basket.gif"
import ytbg from "./assets/design/ytbg.png"
import design1 from "./assets/design/design1.png"
import design2 from "./assets/design/design2.png"
import design3 from "./assets/design/design3.png"
import redesign1 from "./assets/design/redesign1.png"
import redesign2 from "./assets/design/redesign2.png"
import redesign3 from "./assets/design/redesign3.png"
import redesign4 from "./assets/design/redesign4.png"
import nft1 from "./assets/design/nft1.png"
import cd1 from "./assets/design/cd1.png"
import cd2 from "./assets/design/cd2.png"
import cd3 from "./assets/design/cd3.jpg"
import locandina1 from "./assets/design/locandina1.png"
import locandina2 from "./assets/design/locandina2.png"
import locandina3 from "./assets/design/locandina3.png"
import locandina4 from "./assets/design/locandina4.png"
import locandina5 from "./assets/design/locandina5.png"
import locandina6 from "./assets/design/locandina6.png"
import locandina7 from "./assets/design/locandina7.png"
import locandina8 from "./assets/design/locandina9.png"
import locandina9 from "./assets/design/locandina10.png"
import locandina10 from "./assets/design/locandina8.jpg"
import app1 from "./assets/design/app1.png"
import app2 from "./assets/design/app2.png"
import app3 from "./assets/design/app3.png"
import CustomCursor from "./CustomCursor";
import Navbar from "./Navbar";
// import ScrollToTop from './ScrollToTop';
import locandina12 from "./assets/design/luca2.jpg"
import locandina11 from "./assets/design/lirico.jpg"
import nord from "./assets/gif/nord1.gif";
import nordspeed from "./assets/gif/speed.gif";
import nord2 from "./assets/gif/nord2.gif";
import nordspeed2 from "./assets/gif/speed2.gif";
import Spline from '@splinetool/react-spline';
const ProjectButton = () => {
  return (
    <div className="project-button">
      <p>
        <a>Termini & servizi</a> ● <a>Privacy Policy</a> ●{" "}
        <a>Vitali Pisani 2023</a>
      </p>
    </div>
  );
};



export default function App() {

  // useEffect(() => {
  //   initGA();
  // }, []);


  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [showMore, setShowMore] = useState(false);

  const handleLoadMore = () => {
    setShowMore(prevShowMore => !prevShowMore);  // Inverte lo stato showMore
  };

  const [showMore1, setShowMore1] = useState(false);

  const handleLoadMore1 = () => {
    setShowMore1(prevShowMore1 => !prevShowMore1);  
  };

  const [showMore3, setShowMore3] = useState(false);

  const handleLoadMore3 = () => {
    setShowMore3(prevShowMore3 => !prevShowMore3);  
  };



  const [showMore4, setShowMore4] = useState(false);
  const handleLoadMore4 = () => {
    setShowMore4(prevShowMore4 => !prevShowMore4);  
  };


  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    // Aggiorna la percentuale di caricamento ogni 10 millisecondi
    const interval = setInterval(() => {
      setPercentage(prev => (prev < 100 ? prev + 1 : 100));
    }, 10);  // Aumenta dell'1% ogni 10 millisecondi, quindi impiegherà 1 secondo (1000 millisecondi) per raggiungere il 100%

    // Nascondi il caricatore e cancella l'intervallo dopo 1 secondo
    const timer = setTimeout(() => {
      setLoading(false);
      clearInterval(interval);
    }, 1000);  // 1 secondo

    return () => {
      clearTimeout(timer);
      clearInterval(interval);  // Pulizia dell'intervallo al smontaggio      
    };
  }, []);

  return (
    <>
    
      {loading ? (
        <>
        <div style={{ background: '#060606', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="loading-vitali">
            <h1>VITALI PISANI</h1>
            <div style={{ display : 'flex', alignItems: 'center'}} >
            <div className='circle-loading'></div>
            <DominoSpinner color="white" />
           
            <p style={{ color: 'white', marginTop: '20px' }}>{percentage}%</p>  {/* Mostra la percentuale di caricamento */}
          
            </div></div>
        </div>
        <ProjectButton />
        </>
      ) : (
        <>
      
      <ProjectButton />
      <Navbar />
      <CustomCursor />



      
      <div className="App">
        <h1>VITALI PISANI</h1>
        <hr />
        <LazyImage className="VitaliPisani" src={Image} />
        <p>hi, im a Unicorn designer:</p>
        <h2>
            Front-end Developer 
          <br className='mobile-br'  />
          Full-Stack Designer 
        </h2>
       <img src={Image1} className='progr'/><br/>
       <button className="btt-EV" onClick={handleOpenModal}>Parlami del tuo progetto →</button>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
      </div>
      <br />
      <br />

      <div className="container-title" id="portfolio">
    
        <p>Lavori recenti</p>
        <h2>
        Video Teaser
        </h2>
      </div>

      <div className="portfolio-container" >
      <LazyImage src={Speedart} />
        <LazyImage src={TheHood9} />

        <LazyImage src={TheHood8} />
        {showMore && (
          <>
                     {/* <LazyImage src={nord} /> */}
                     <LazyImage src={nordspeed} />
                     <LazyImage src={nord2} />
                     <LazyImage src={nordspeed2} />
                     {/* <LazyImage src={basket} />  */}
            <LazyImage src={theOne} />
            <LazyImage src={keytech} />
            <LazyImage src={sento} />

         

            {/* <LazyImage src={directed} />
   
        
            
            {/* ... altre immagini ... */}
          </>
        )}
      </div>
      <div className='btt-cont'>
      
         <button onClick={handleLoadMore} className="btt-EV1">
         {showMore ? 'Chiudi' : 'Carica altri'}
       </button>
    
      </div>




<div className="container-title">
<hr/>
       <p>Lavori recenti</p>
       <h2>
       Editing & Shooting Music Video
       </h2>
     </div>

      <div className="portfolio-container" >
      
        {/*  */}
        <LazyImage src={BeQuiet} />
        <LazyImage src={Pochet} />

        <LazyImage src={Sesolo} />
{showMore3 && (
          <>
           
            <LazyImage src={yep} />
            <LazyImage src={morphine} />
            <LazyImage src={brasky} />
            {/* ... altre immagini ... */}
          </>
        )}
      </div>
      <div className='btt-cont'>
      
         <button onClick={handleLoadMore3} className="btt-EV1">
         {showMore3 ? 'Chiudi' : 'Carica altri'}
       </button>
    
      </div>
<div className="container-title">
<hr/>
       <p>Lavori recenti & Prototipi</p>
       <h2>
     Front-end Redesign   
       </h2>
     </div>

<div className="portfolio-container" >
    

        {/*  */}

        <LazyImage src={redesign1} /> 
        <LazyImage src={redesign2} />
      
        <LazyImage src={redesign4} />
        {showMore1 && (
          <>
           
           <LazyImage src={redesign3} />
        
            {/* ... altre immagini ... */}
          </>
        )}
      </div>
      <div className='btt-cont'>
      
         <button onClick={handleLoadMore1} className="btt-EV1">
         {showMore1 ? 'Chiudi' : 'Carica altri'}
       </button>
    
      </div>
        <div className="container-title">
<hr/>
       <p>Lavori recenti & Prototipi</p>
       <h2>
      Ui & Ux Design
       </h2>
     </div>
     <div className="portfolio-container" >
    
        <LazyImage src={design1} />
        <LazyImage src={design2} />
        <LazyImage src={design3} /> 
        </div>
        <div className='btt-cont'  >
<button className="btt-EV1">Carica altri</button></div>
        {/*  */}





        <div className="container-title">
<hr/>
       <p>Lavori recenti & Prototipi</p>
       <h2>
      Web Applications
       </h2>
     </div>
     <div className="portfolio-container" >
    
        <LazyImage src={app1} />
        <LazyImage src={app2} />
        <LazyImage src={app3} /> 
        </div>
        <div className='btt-cont'  >
<button className="btt-EV1">Carica altri</button></div>



<div className="container-title">
<hr/>
       <p>Lavori recenti</p>
       <h2>
      Youtube background (2011)
       </h2>
     </div>
     <div className="portfolio-container-special" >
    
        <LazyImage className="img-special" src={ytbg} />
        <br/>
        <iframe
              
              
                src="https://www.youtube.com/embed/fZkirVxDx_o"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        <iframe
                
                src="https://www.youtube.com/embed/GareuZc_gDQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>




   

{/*  */}
<div className="container-title">
<hr/>
       <p>Lavori recenti</p>
       <h2>
       Music cover
       </h2>
     </div>
     <div className="portfolio-container" >
     <LazyImage src={cd3} />
        <LazyImage src={cd1} />
        <LazyImage src={cd2} />
      
      
        </div>

 <div className="container-title">
<hr/>
       <p>Lavori recenti</p>
       <h2>
     Locandine
       </h2>
     </div>
     <div className="portfolio-container" >
    
  
        <LazyImage src={locandina11} />
        <LazyImage src={locandina12} />
        <LazyImage src={locandina1} />
        {showMore4 && (
          <>
           
           <LazyImage src={locandina4} />
        <LazyImage src={locandina5} />
        <LazyImage src={locandina6} />
     <LazyImage src={locandina10} />
     <LazyImage src={locandina2} />
        <LazyImage src={locandina3} />
           {/* <LazyImage src={locandina8} />
        <LazyImage src={locandina9} /> */}
        
            {/* ... altre immagini ... */}
          </>
        )}
      </div>
      <div className='btt-cont'>
      
         <button onClick={handleLoadMore4} className="btt-EV1">
         {showMore4 ? 'Chiudi' : 'Carica altri'}
       </button>
    
      </div>
<br/> <br/>
      

        {/* <div className='btt-cont'  >
<button className="btt-EV1">Carica altri</button></div>  */}










{/*  */}

        <div className="portfolio-container" >
               {/* <img src={redesign1} /> 
        <img src={design1} />
        <img src={design2} /> */}
        {/* <img src={design3} /> */}

      </div>

      <div id="section2" >
      <Spline scene="https://prod.spline.design/nIe3pmg4IhLuvuOD/scene.splinecode" />


      </div>


        </>
      )}
    </>
  );
  
}

// const expertiseData = [
//   {
//     name: "Vitali Pisani",
//     date: "2011",
//     direction: "https://i.ibb.co/PgMRc7Y/cinema4.png"
//   },
//   // ... altri oggetti
// ];

// function ExpertiseList({ data }) {
//   return (
//     <div className="expertise-container">
//       {expertiseData.map((item, index) => (
//         <>
//           <div className={`expertise-vitali-pisani`} key={index}>
//             <p>{item.date}</p>
//             <img src={item.direction} alt={item.name} />
//             <p>Cinema 4D (3D)</p>
//           </div>
//         </>
//       ))}
//     </div>
//   );
// }


// CustomCursor.js
import React, { useEffect, useState } from "react";
import "./CustomCursor.scss"; // Importa il file CSS

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isMoving, setIsMoving] = useState(false);

  const onMouseMove = (event) => {
    const { pageX: x, pageY: y } = event;
    setPosition({ x, y });
    setIsMoving(true); // Imposta isMoving a true quando il mouse si muove
  };

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);

    // Pulizia: rimuove l'event listener quando il componente viene smontato
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  useEffect(() => {
    if (isMoving) {
      const timer = setTimeout(() => setIsMoving(false), 100); // Resetta isMoving a false dopo 100ms
      return () => clearTimeout(timer); // Pulizia del timer
    }
  }, [isMoving]);

  return (
    <div
      className={`cursor ${isMoving ? "cursor-moving" : ""}`}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`
      }}
    />
  );
};

export default CustomCursor;

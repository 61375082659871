// src/LazyImage.js

import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';

const ImageLoader = () => (
  <ContentLoader 
    speed={2}
    width={400}  // Imposta la larghezza desiderata
    height={300}  // Imposta l'altezza desiderata
    backgroundColor="#141414"
    foregroundColor="#141414"
  >
    <rect x="0" y="0" rx="5" ry="5" width="400" height="300" />
  </ContentLoader>
);

const LazyImage = ({ src, alt, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <img
        src={src}
        alt={alt}
        style={{ display: loaded ? 'block' : 'none' }}
        onLoad={() => setLoaded(true)}
        {...props}
      />
      {!loaded && <ImageLoader />}
    </>
  );
};

export default LazyImage;

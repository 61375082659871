/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFormModel = /* GraphQL */ `
  mutation CreateFormModel(
    $input: CreateFormModelInput!
    $condition: ModelFormModelConditionInput
  ) {
    createFormModel(input: $input, condition: $condition) {
      id
      Nome
      Email
      messaggio
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFormModel = /* GraphQL */ `
  mutation UpdateFormModel(
    $input: UpdateFormModelInput!
    $condition: ModelFormModelConditionInput
  ) {
    updateFormModel(input: $input, condition: $condition) {
      id
      Nome
      Email
      messaggio
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFormModel = /* GraphQL */ `
  mutation DeleteFormModel(
    $input: DeleteFormModelInput!
    $condition: ModelFormModelConditionInput
  ) {
    deleteFormModel(input: $input, condition: $condition) {
      id
      Nome
      Email
      messaggio
      createdAt
      updatedAt
      __typename
    }
  }
`;
